import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Footer = () => {
    return (
        <>
            <Typography variant="body1" align="right"><a href="#" target="_self">Omhoog</a></Typography>
            <Typography variant="body2" color="text.secondary" align="left">
                {"Copyright © "}
                <Link color="inherit" href="https://dokdata.nl/" target="_blank">
                    DOK data B.V.
                </Link>{" "}
                {new Date().getFullYear()}
            </Typography>
        </>
    )
}

export default Footer
