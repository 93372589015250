import React, { useState } from 'react';
import Iframe from 'react-iframe';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const CalendarDialog = ({ open, handleClose, iframeSrc }) => {
    return (
        iframeSrc && (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
            // fullScreen="true"
            >
                <DialogTitle id="alert-dialog-slide-title">Plan een ongevalrisico.nl demosessie in met DOK data</DialogTitle>
                <DialogContent>
                    <Iframe
                        src={iframeSrc}
                        title='Plan een ongevalrisico.nl demosessie in met DOK data'
                        height="800px"
                        width="100%"
                        scrolling="yes"
                        frameBorder={0}
                        sandbox="" //insecure: "allow-scripts allow-same-origin"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Sluiten
                    </Button>
                </DialogActions>
            </Dialog>
        )
    )
}

export default CalendarDialog
