import { Link, animateScroll as scroll } from "react-scroll";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';

const Features = () => {
    return (
        <>
            <div className='filler' />
            <Grid container justifyContent="center" alignItems="flex-start" spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <LooksOneIcon />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" color="text.primary" align="left">
                                Selecteer risico-indicatoren
                            </Typography>
                            <Typography variant="body2" color="text.primary" sx={{ height: 80, overflow: 'auto' }}>
                                Onveilig ingerichte fietspaden, ongeloofwaardige 30-wegen, snelheidsovertredingen, middelbare scholieren, etc.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small" color='primary' href='#risks'>Lees meer</Button> */}
                            <Link
                                color='primary'
                                to="risks"
                                smooth={true}
                                //offset={-70}
                                duration={500}
                            >
                                <Typography variant="body1" color="primary" sx={{ paddingLeft: '10px', cursor: 'pointer' }}>Lees meer</Typography>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Card>
                        <LooksTwoIcon />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" color="text.primary" align="left">
                                Analyseer uitkomsten
                            </Typography>
                            <Typography variant="body2" color="text.primary" sx={{ height: 80, overflow: 'auto' }}>
                                Vanuit de app: kaartweergave, streetview beelden, top100 lijsten, inzichten per gemeente, plaats, wijk en buurt, etc.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small" color='primary' href='#app'>Lees meer</Button> */}
                            <Link
                                color='primary'
                                to="app"
                                smooth={true}
                                //offset={-70}
                                duration={500}
                            >
                                <Typography variant="body1" color="primary" sx={{ paddingLeft: '10px', cursor: 'pointer' }}>Lees meer</Typography>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Card>
                        <Looks3Icon />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div" color="text.primary" align="left">
                                Verbeter de weg
                            </Typography>
                            <Typography variant="body2" color="text.primary" sx={{ height: 80, overflow: 'auto' }}>
                                Gebruik inzichten uit de app in de praktijk voor onderbouwing verkeersveiligheidsbeleid of voor uitvoeringsagenda's en verminder het aantal verkeersongevallen.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {/* <Button size="small" color='primary' href='#cases'>Lees meer</Button> */}
                            <Link
                                color='primary'
                                to="cases"
                                smooth={true}
                                //offset={-70}
                                duration={500}
                            >
                                <Typography variant="body1" color="primary" sx={{ paddingLeft: '10px', cursor: 'pointer' }}>Lees meer</Typography>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Features