import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ForumIcon from '@mui/icons-material/Forum';
import ArticleIcon from '@mui/icons-material/Article';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import StorageIcon from '@mui/icons-material/Storage';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import Paper from '@mui/material/Paper';

const Cases = () => {
    //parallax
    const parallaxLength = 31;

    return (
        <>
            <Typography variant="h4" color="text.primary" align="center" style={{ paddingTop: '2rem' }}>
                {"Praktijkvoorbeelden"}
            </Typography>
            <div className='fillerSmall' />
            <Typography variant="body1" color="text.primary" align="left">
                Wegbeheerders en verkeerskundigen passen ongevalrisico.nl op diverse manieren in de praktijk toe om de verkeersveiligheid te verhogen.
            </Typography>
            <div className='filler' />
            <Grid container justifyContent="center" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} lg={10} style={{ height: '480px' }}>
                    <Paper sx={{ padding: '1rem' }}>
                        <Parallax pages={parallaxLength} style={{ position: 'relative', width: '100%', height: '450px' }} className='parallax-container'>

                            {/* 0-3 */}
                            <ParallaxLayer offset={0} speed={0.5} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Handig om in de app te kijken bij overleggen met bewonersgroepen of een verkeerscommissie over een bepaalde straat of een buurt."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Michiel van Esch, verkeerskundige</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 0, end: 3 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    <ForumIcon style={{ paddingTop: '5px' }} /> Burgermeldingen en gremia
                                </Typography>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 0, end: 3 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                                <img src='img/roadNotification.png' height='350px' className="hide_mobile" />
                            </ParallaxLayer>
                            <ParallaxLayer style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                            </ParallaxLayer>
                            <ParallaxLayer offset={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Als een bewoner meldt dat in een straat te hard wordt gereden of een oversteek onveilig is kan ik met dit model controleren of dit ook echt zo is."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Nienke van Meekeren, junior verkeerskundige</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>
                            <ParallaxLayer offset={2} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Is er echt iets aan de hand of niet? Ik gebruik de app sinds vorig jaar voor het bespreken van blackspotlocaties in combinatie met het voorspelmodel."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Deborah Heijkoop, adviseur mobiliteit</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>

                            {/* 4 */}

                            {/* 5-8 */}
                            <ParallaxLayer sticky={{ start: 5, end: 8 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    <ArticleIcon style={{ paddingTop: '5px' }} /> Verkeersveiligheidsbeleid, politiek bestuurlijk
                                </Typography>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 5, end: 8 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                                <img src='img/roadCombination.png' height='350px' className="hide_mobile" />
                            </ParallaxLayer>
                            <ParallaxLayer offset={6} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Ik gebruik de app met name voor onderbouwing van beleid. Wat je vaak ziet is dat een bestuurlijke of politieke realiteit ontstaat als een ongeval heeft plaatsgevonden, dit is een goed instrument om objectief te laten zien wat er speelt op het gebied van verkeersveiligheid."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Martijn Plukkel, senior beleidsmedewerker ruimtelijke ontwikkeling</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>
                            <ParallaxLayer offset={7} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Op dit moment ben ik bezig om te kijken hoe nieuw verkeersveiligheidsbeleid vorm te geven. Er zijn lijstjes van raadsleden en andere gremia, dit biedt de mogelijkheid een bepaalde objectiviteit erin te brengen en prioriteiten te stellen."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Michael Woerden, beleidsmedewerker verkeer/projectleider ruimtelijke ontwikkeling</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>
                            <ParallaxLayer offset={8} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "De nieuwe risico-indicatoren over basisscholen en middelbare scholieren gaan helpen bij de motie verkeersveiligheid rondom schoolomgevingen."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Sarah de Jong, adviseur mobiliteitsdata</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>

                            {/* 9 */}

                            {/* 10-12 */}
                            <ParallaxLayer sticky={{ start: 10, end: 12 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    <ModelTrainingIcon style={{ paddingTop: '5px' }} /> Nieuwe inzichten
                                </Typography>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 10, end: 12 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                                <img src='img/outOfTheBox.png' height='350px' className="hide_mobile" />
                            </ParallaxLayer>
                            <ParallaxLayer offset={11} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "De usual suspects zie je wel, maar ik zie ook nieuwe dingen oppoppen. Daar wil ik meer de diepte mee in."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Geert Hendriks, adviseur mobiliteit</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>

                            {/* 13 */}

                            {/* 14-17 */}
                            <ParallaxLayer sticky={{ start: 14, end: 17 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    <PedalBikeIcon style={{ paddingTop: '5px' }} /> Fietsveiligheid
                                </Typography>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 14, end: 17 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                                <img src='img/bicycleAccident.png' height='350px' className="hide_mobile" />
                            </ParallaxLayer>
                            <ParallaxLayer offset={15} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Echt een hele mooie uitbreiding, zeker de fietsdata en de filterfuncties. Ook het dashboard met de filters per wijk voor gesprekken met fiets en verkeersveiligheid bij elkaar; de top 20 per wijk bespreken."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Benjamin Schaipp, adviseur fietsinfrastructuur</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>
                            <ParallaxLayer offset={16} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Met de fietsdata er nu bij nog handiger voor Feijenoord. Ik ga hier zo met de ondersteuner van Feijenoord voor zitten."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Evelien Roelands, adviseur mobiliteit</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>

                            {/* 18 */}

                            {/* 19-21 */}
                            <ParallaxLayer sticky={{ start: 19, end: 22 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    <ArticleIcon style={{ paddingTop: '5px' }} /> Subsidieaanvragen
                                </Typography>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 19, end: 22 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                                <img src='img/roadDesignAlt.png' height='350px' className="hide_mobile" />
                            </ParallaxLayer>
                            <ParallaxLayer offset={20} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Het model is handig voor onderbouwingen van subsidieaanvragen richting de MRDH zoals voor Vuurplaat onlangs."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Deborah Heijkoop, adviseur mobiliteit</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>
                            <ParallaxLayer offset={21} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Ook handig voor het aanvragen van subsidie voor een bepaalde plek bij de provincie, voor onderbouwing subsidieaanvragen."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Michiel van Esch, verkeerskundige</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>

                            {/* 23 */}

                            {/* 24-27 */}
                            <ParallaxLayer sticky={{ start: 24, end: 27 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    <StorageIcon style={{ paddingTop: '5px' }} /> Data op één plek
                                </Typography>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 24, end: 27 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                                <img src='img/roadJunction.png' height='350px' className="hide_mobile" />
                            </ParallaxLayer>
                            <ParallaxLayer offset={25} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "De app is een soort centraal punt waar veel data bij elkaar staat."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Roel van Rijthoven, coördinator mobiliteitsdata en verkeersmodellen</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>
                            <ParallaxLayer offset={26} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Ook mooi dat alle data op één plek samenkomt."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Martijn Plukkel, senior beleidsmedewerker ruimtelijke ontwikkeling</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>

                            {/* 28 */}

                            {/* 29-31 */}
                            <ParallaxLayer sticky={{ start: 29, end: 31 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'left' }}>
                                <Typography variant="h5" color="text.primary" align="left">
                                    <DataThresholdingIcon style={{ paddingTop: '5px' }} /> Verbeteren datakwaliteit
                                </Typography>
                            </ParallaxLayer>
                            <ParallaxLayer sticky={{ start: 29, end: 31 }} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left' }}>
                                <img src='img/dataQuality.png' height='350px' className="hide_mobile" />
                            </ParallaxLayer>
                            <ParallaxLayer offset={30} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                <Timeline position="right" style={{ marginTop: '100px' }}>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                                <FormatQuoteIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            <Typography variant='body2' color='text.primary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>
                                                "Ook handig dat we per wegvak en kruispunt notities bij kunnen houden in het geval er iets niet klopt."
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary' style={{ backgroundColor: '#FFF', opacity: 0.9, borderRadius: '10px', padding: '2px' }}>Roel van Rijthoven, coördinator mobiliteitsdata en verkeersmodellen</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </ParallaxLayer>

                        </Parallax>
                    </Paper>
                </Grid>
            </Grid>

        </>
    )
}

export default Cases
