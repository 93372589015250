import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import SpeedIcon from '@mui/icons-material/Speed';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import SchoolIcon from '@mui/icons-material/School';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import FadeIn from "react-fade-in"; //https://www.npmjs.com/package/react-fade-in
import Paper from '@mui/material/Paper';

const Risks = () => {
    //parallax
    const parallaxStyle = { display: 'center', alignItems: 'center' }

    return (
        <>
            <FadeIn>
                <Typography variant="h4" color="text.primary" align="center">
                    {"Risico-indicatoren"}
                </Typography>
                <div className='fillerSmall' />
                <Typography variant="body1" color="text.primary" align="left">
                    Diverse uitgewerkte risico-indicatoren waar mogelijk gebaseerd op het <a href='https://www.kennisnetwerkspv.nl/' target='_blank'>Strategisch Plan Verkeersveiligheid (SPV)</a> en een machine learning voorspelmodel voor 'out of the box' inzichten. Zowel landelijk voor alle wegbeheerders als specifiek maatwerk. Indien beschikbaar met gebruik van open data zoals <a href="https://nationaalwegenbestand.nl/" target="_blank">NWB</a>, <a href="https://www.kadaster.nl/zakelijk/registraties/basisregistraties/bgt" target="_blank">BGT</a>, <a href="https://www.openstreetmap.org/" target="_blank">Openstreetmap</a>, <a href="https://www.ndw.nu/" target="_blank">NDW V85</a> en <a href="https://www.openov.nl" target="_blank">OpenOV</a>.
                </Typography>
            </FadeIn>
            <div className='filler' />
            <Grid container justifyContent="center" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} lg={10} style={{ height: '480px' }}>

                    <Paper sx={{ padding: '1rem' }}>
                        {/* parralax effect replaced with simple divs due to too much scrolling */}
                        <Parallax style={{ position: 'relative', width: '100%', height: '450px' }} className='parallax-container'>

                            <div style={{ height: '450px' }}>
                                <div style={{ ...parallaxStyle, justifyContent: 'center' }}>
                                    <Typography variant="h5" color="text.primary" align="left">
                                        <SpeedIcon style={{ paddingTop: '5px' }} /> Snelheidsovertredingen
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>
                                        Risico-indicator op basis van daadwerkelijk gereden snelheden uit floating car data.
                                    </Typography>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src='img/speed.png' height='300px' />
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: '450px' }}>
                                <div style={{ ...parallaxStyle, justifyContent: 'center' }}>
                                    <Typography variant="h5" color="text.primary" align="left">
                                        <PedalBikeIcon style={{ paddingTop: '5px' }} /> Te drukke fietspaden
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>
                                        Toetsing fietspaden volgens CROW richtlijnen (voldoet de breedte t.o.v. intensiteit en aantal richtingen).
                                    </Typography>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src='img/bicycle.png' height='300px' />
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: '450px' }}>
                                <div style={{ ...parallaxStyle, justifyContent: 'center' }}>
                                    <Typography variant="h5" color="text.primary" align="left">
                                        <ModelTrainingIcon style={{ paddingTop: '5px' }} /> Voorspelmodel
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>
                                        Het artificial intelligence / machine learning model zoekt verschillen in eigenschappen tussen wegen en kruispunten waarop wel en waarop geen ongevallen gebeuren. Handig voor nieuwe inzichten.
                                    </Typography>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src='img/outOfTheBox.png' height='250px' />
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: '450px' }}>
                                <div style={{ ...parallaxStyle, justifyContent: 'center' }}>
                                    <Typography variant="h5" color="text.primary" align="left">
                                        <DesignServicesIcon style={{ paddingTop: '5px' }} /> Ongeloofwaardige 30-wegen
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>
                                        Toetsing of 30-weg is ingericht volgens richtlijnen, door bijvoorbeeld te kijken naar type verharding, type fietsvoorziening, intensiteiten, aanwezigheid rechtstanden/drempels/breedte en/of de voorrangssituatie op kruispunten.
                                    </Typography>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src='img/roadDesign.png' height='250px' />
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: '450px' }}>
                                <div style={{ ...parallaxStyle, justifyContent: 'center' }}>
                                    <Typography variant="h5" color="text.primary" align="left">
                                        <PedalBikeIcon style={{ paddingTop: '5px' }} /> Onveilig ingerichte fietspaden
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>
                                        Toetsing fietspaden volgens kenmerken factsheet SPV (vergevingsgezinde rand/berm, obstakels, kwaliteit verharding etc.).
                                    </Typography>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src='img/bicycleAccident.png' height='300px' />
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: '450px' }}>
                                <div style={{ ...parallaxStyle, justifyContent: 'center' }}>
                                    <Typography variant="h5" color="text.primary" align="left">
                                        <SchoolIcon style={{ paddingTop: '5px' }} /> Basisschoolleerlingen en middelbare scholieren
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>
                                        Risicoscores op basis van (potentiële) aanwezigheid van leerlingen op basisscholen en/of middelbare scholen. Scoort bijvoorbeeld hoog op locaties dichtbij een school met veel leerlingen of in buurten waarin veel jongeren wonen (CBS).
                                    </Typography>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src='img/school.png' height='200px' />
                                    </div>
                                </div>
                            </div>

                            <div style={{ height: '450px' }}>
                                <div style={{ ...parallaxStyle, alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="h5" color="text.primary" align="left">
                                        <DynamicFeedIcon style={{ paddingTop: '5px' }} /> En nog veel meer of maak uw eigen risico
                                    </Typography>
                                    <ul className='checklist'>
                                        <li className='checklist'><Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>Jongeren op brom- en snorfietsen</Typography></li>
                                        <li className='checklist'><Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>Oudere (elektrische) fietsers</Typography></li>
                                        <li className='checklist'><Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>Snelheidsverschillen 60-wegen</Typography></li>
                                        <li className='checklist'><Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>Of een eigen maatwerkrisico aansluitend op beleid</Typography></li>
                                    </ul>
                                    <Typography variant="body1" color="text.primary" align="left" style={{ backgroundColor: '#FFF', opacity: 0.9 }}>Neem contact op voor mogelijkheden.</Typography>
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <img src='img/roadDirections.png' height='220px' />
                                    </div>
                                </div>

                            </div>

                        </Parallax>
                    </Paper>

                </Grid>
            </Grid>
        </>

    )
}

export default Risks;