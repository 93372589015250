import './App.css';
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import FadeIn from "react-fade-in"; //https://www.npmjs.com/package/react-fade-in
//import LazyLoad from "react-lazy-load"; //https://www.npmjs.com/package/react-lazy-load
import Menu from "./components/Menu";
//import HeaderLottie from "./components/HeaderLottie";
import HeaderImage from "./components/HeaderImage";
import Features from "./components/Features"
import Footer from "./components/Footer";
import HeaderText from "./components/HeaderText";
import Clients from "./components/Clients";
import Risks from "./components/Risks";
import DashboardApp from "./components/DashboardApp";
import Cases from "./components/Cases";
import About from "./components/About";
import Contact from "./components/Contact";
import Disclaimer from "./components/Disclaimer";

let theme = createTheme({
  palette: {
    primary: {
      light: '#1c77fc', //'#bc5aec',
      main: '#0d6efd', //'#5754e1',
      dark: '#033d91', //#8d5de7',
      contrastText: '#fff',
    },
    secondary: {
      light: '#d1d1d1',
      main: '#b6b6b6',
      dark: '#9e9e9e',
      contrastText: '#000',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#fff',
    // },
    text: {
      primary: '#5a5a5a',
      secondary: '#b6b6b6',
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 500,
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className='fillerLarge' />
        <Menu />
        <Container maxWidth="lg">

          <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
            <Grid item xs={12} sm={6}>
              <FadeIn>
                <HeaderText />
              </FadeIn>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FadeIn>
                <HeaderImage />
              </FadeIn>
            </Grid>
          </Grid>

          <div className='fillerLarge' />
          <div id="features"></div>
          <FadeIn>
            <Features />
          </FadeIn>

          <div className='fillerLarge' />
          <div id="risks"></div>
          <Risks />

          <div className='fillerLarge' />
          <div id="app"></div>
          <DashboardApp />

          <div className='fillerLarge' />
          <div id="cases"></div>
          <Cases />

          <div className='fillerLarge' />
          <div id="clients"></div>
          {/* <FadeIn> */}
          <Clients />
          {/* </FadeIn> */}

          <div className='fillerLarge' />
          <div id="about"></div>
          <About />

          <div className='fillerLarge' />
          <div id="contact"></div>
          <Contact />

          <div className='fillerLarge' />
          <div id="disclaimer"></div>
          <Disclaimer />

          <div className='fillerLarge' />
          <Footer />

        </Container>
      </ThemeProvider>
    </>
  );
}

export default App;
