import { useState, useEffect, useRef, useReducer } from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactPlayer from 'react-player';
import LazyLoad from "react-lazy-load"; //https://www.npmjs.com/package/react-lazy-load

const DashboardApp = () => {
    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Typography variant="h4" color="text.primary" align="center">
                {"App"}
            </Typography>
            <div className='fillerSmall' />
            <Typography variant="body1" color="text.primary" align="left">
                Uitgebreid inzicht in risicovolle locaties om het aantal verkeersslachtoffers te verminderen.
            </Typography>
            <div className='filler' />
            <Grid container justifyContent="center" alignItems="flex-start" spacing={4}>
                <Grid item xs={12} sm={4}>
                    {itemData.map((item) => (
                        <Accordion expanded={expanded === item.key} onChange={handleChange(item.key)} key={item.key}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography variant="body1" color="text.primary">{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2" color="text.primary">{item.description}
                                    {item.linkUrl != '' && <a href={item.linkUrl} target="_blank">{item.linkText}</a>}
                                    {item.linkUrl != '' && '.'}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
                <Grid item xs={12} sm={8}>
                    <div className='player-wrapper' style={{ maxHeight: '480px' }}>
                        <LazyLoad height={480} offsetVertical={250}>
                            <ReactPlayer
                                className='react-player fixed-bottom'
                                url='video/Ongevalrisico_app_demo.mp4'
                                width='100%'
                                height='100%'
                                muted={true}
                                controls={true}
                                playing={false}
                            />
                        </LazyLoad>
                    </div>
                    {/* <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}><iframe src="https://player.vimeo.com/video/656110741?autoplay=1&color=FFF&muted=0&loop=1&title=0&transparent=1&background=1" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */}

                </Grid>
            </Grid>
        </>
    )
}

export default DashboardApp

const itemData = [
    {
        key: '1',
        title: 'Risicoscores op kaart',
        description: 'Per wegvak / fietspad / kruispunt een risicoscore van laag (groen) tot hoog (rood, zwart) zichtbaar op de kaart. De risico-indicatoren zijn waar mogelijk gebaseerd op het SPV en verkeerskundig onderbouwd.',
        linkUrl: '',
        linkText: ''
    },
    {
        key: '2',
        title: 'Risico-indicatoren combineren',
        description: 'Selecteer één of meer risico-indicatoren en combineer uitkomsten in onze modulaire app. Denk aan onveilig ingerichte fietspaden, ongeloofwaardige 30-wegen, snelheidsovertredingen, middelbare scholieren, etc.',
        linkUrl: '',
        linkText: ''
    },
    {
        key: '3',
        title: 'Gebruiksvriendelijk',
        description: 'Specifiek ontwikkeld voor verkeerskundigen en wegbeheerders. Onder andere een eenvoudige selectie van risico\'s, helpiconen met uitleg en optionele achterliggende documentatie.',
        linkUrl: '',
        linkText: ''
    },
    {
        key: '4',
        title: 'Detailweergave',
        description: 'Bekijk streetviewbeelden en gekoppelde data van geselecteerde wegvakken of kruispunten voor expertbeoordeling.',
        linkUrl: '',
        linkText: ''
    },
    {
        key: '5',
        title: 'Dashboard',
        description: 'Een dashboard met top100 lijsten, vergelijkingen per gemeente / plaats / wijk / buurt om van elkaar te leren en om beleidsdoelstellingen te monitoren.',
        linkUrl: '',
        linkText: ''
    },
    {
        key: '6',
        title: 'Zoekfunctie',
        description: 'Zoek op straat, plaats, gemeente, etc.',
        linkUrl: '',
        linkText: ''
    },
    {
        key: '7',
        title: 'Berichten t.b.v. datakwaliteit',
        description: 'Plaats berichten bij datavelden, bijvoorbeeld als een eigenschap van een weg niet klopt.',
        linkUrl: '',
        linkText: ''
    },
    {
        key: '8',
        title: 'Schaalbare backend',
        description: 'Onze app draait op de stabiele en schaalbare backend van ',
        linkUrl: 'https://www.ellipsis-drive.com',
        linkText: 'Ellipsis Drive'
    },
    {
        key: '9',
        title: 'Schappelijke kosten',
        description: 'Aantrekkelijke kosten voor gebruik van de app en voor maatwerk.',
        linkUrl: '',
        linkText: ''
    },
];