import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtjQ3WMcjdScjz54hj1ODXR70X5swE4Kk",
  authDomain: "ongevalrisico-site-d91f8.firebaseapp.com",
  projectId: "ongevalrisico-site-d91f8",
  storageBucket: "ongevalrisico-site-d91f8.appspot.com",
  messagingSenderId: "28785014661",
  appId: "1:28785014661:web:4be0c484b097d21e5bb6ec",
  measurementId: "G-047JXKRQDG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.querySelector('#root'),
);
