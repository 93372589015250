const HeaderImage = () => {
    return (
        <img
            className='rounded' //'grayscale'
            align='center'
            src='img/ongevalrisico-header.webp'
            alt='Ongevalrisico app'
            loading="lazy"
            width="100%"
        />
    )
}

export default HeaderImage
