import Typography from "@mui/material/Typography";

const Disclaimer = () => {
    return (
        <>
            <Typography variant="h4" color="text.primary" align="center">
                {"Disclaimer"}
            </Typography>
            <div className='filler' />
            <Typography variant="body1" color="text.primary" align="left">
                De uitkomsten en risicoscores in de dashboard applicatie van ongevalrisico.nl zijn puur gebaseerd op data en komen niet altijd overeen met de werkelijkheid. De uitkomsten dienen enkel ter advisering gebruikt te worden. <a href="https://dokdata.nl" target="_blank">DOK data B.V.</a> en andere betrokken partners zijn niet aansprakelijk voor schade of het eventueel nadelig uitpakken van (verkeerskundige) besluiten die op basis van de dashboard applicatie gemaakt worden.
            </Typography>
        </>
    )
}

export default Disclaimer